import logo from './logo.svg';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';import './App.css';
import { BrowserRouter as Router, Route, Switch, Routes } from 'react-router-dom';
import React from 'react';
import Navbaar from './Components/Navbar';
import Maincontent from './Components/Content';
import Horizontalcards from './Components/horizontalcards';
import Footer from './Components/footer';
import { Provider } from 'react-redux';
import store from './Components/Redux/store';
import YourReactComponent from './Components/test';
import ContactUS from './Components/contactus';
import Signin from './Components/signin';
import Signup from './Components/signup';
import ForgetPassword from './Components/forgetpassword';
import RequestDemo from './Components/RequestDemo';

function App() {





  return (
    <div className="App">

<Provider store={store}>



    <div>
    <Navbaar/>
    





      <Routes>
        
        <Route path="/contactus" element={ <ContactUS />}> </Route>
        {/* <Route path="/signin" element={ <Signin />}> </Route>
        <Route path="/signup" element={ <Signup />}> </Route>
        <Route path="/forgetpassword" element={ <ForgetPassword />}> </Route> */}

        <Route path="/" element={ <Maincontent />}> </Route>
        <Route path="/booking-demo" element={ <RequestDemo />}> </Route>


      </Routes>


<Footer/>
</div>


  {/* <Navbaar />

  

    

    <Maincontent/>

    <Horizontalcards /> 

    <Footer /> */}



     {/* < YourReactComponent/>    */}

     </Provider>
    </div>
  );
}

export default App;
