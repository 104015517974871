import React, { createContext, useState, useEffect } from "react";

// Create the context
export const ChatContext = createContext();

// Create the provider
export const ChatProvider = ({ children }) => {
  const sessionDuration = (24 * 60 * 60 * 1000) / 2; // 12 hours in milliseconds
  const [email, setEmail] = useState(() => {
    const savedEmail = localStorage.getItem("chatEmail");
    const expiryTime = localStorage.getItem("chatExpiryTime");
    const now = new Date().getTime();

    // Check if the session is still valid
    if (savedEmail && expiryTime && now < Number(expiryTime)) {
      return savedEmail;
    } else {
      localStorage.removeItem("chatEmail");
      localStorage.removeItem("chatExpiryTime");
      return "";
    }
  });

  useEffect(() => {
    if (email) {
      // Set new expiry time
      const expiryTime = new Date().getTime() + sessionDuration;
      localStorage.setItem("chatEmail", email);
      localStorage.setItem("chatExpiryTime", expiryTime);

      const timer = setTimeout(() => {
        setEmail(""); // Clear email after session expires
        localStorage.removeItem("chatEmail");
        localStorage.removeItem("chatExpiryTime");
      }, sessionDuration);

      // Cleanup on unmount or when email changes
      return () => clearTimeout(timer);
    } else {
      localStorage.removeItem("chatEmail");
      localStorage.removeItem("chatExpiryTime");
    }
  }, [email]);

  return (
    <ChatContext.Provider value={{ email, setEmail }}>
      {children}
    </ChatContext.Provider>
  );
};
