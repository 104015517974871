import React, { useContext, useEffect, useState } from "react";
import chaticon from '/webapps/gosmart_landingpage/src/assets/Group 62.png'
import '/webapps/gosmart_landingpage/src/Components/style/Chat.css'
import Modal from "react-modal";
import chatbox from '/webapps/gosmart_landingpage/src/assets/WappGPT - logo.png'
import minus from '/webapps/gosmart_landingpage/src/assets/minus-cirlce.png'
import plus from '/webapps/gosmart_landingpage/src/assets/close-circle.png'
import chaticon2 from '/webapps/gosmart_landingpage/src/assets/chat.png'
import { ChatContext } from './ChatContext';
import axios from "axios";

export default function Chat() {

  const [showModal, setShowModal] = useState(false);
  const [FlagingChatbox, SetFlagingChatbox] = useState(false)
  const [minimized, setMinimized] = useState(false); // State to handle the minimized state
  const { email, setEmail } = useContext(ChatContext);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission
  const [errorMessage1, setErrorMessage1] = useState(''); // State for error message
  // const [chatdisable, setchatdisable] = useState(false); // State to track submission
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (!email) return;

    const fetchMessages = async () => {
      try {
        const response = await axios.get(`https://goapis.gosmart.ae/get_chat_messages/${email}`);
        console.log(response)
        if (response.status === 200) {
          console.log(response)
          setMessages(response.data.result);
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    // Fetch messages initially and set up polling
    fetchMessages();
    const interval = setInterval(fetchMessages, 1000); // Poll every 5 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [email]);


  // useEffect(()=>{

  //     axios
  //     .get("httpss://goapis.gosmart.ae/details_of/package_pricing/package_id/1")
  //     .then((response) => {
  //       const fetchedData = response.data.data;
  //       setData(fetchedData);
  //       if (fetchedData.length > 0) {
  //         setSelectedPlan(fetchedData[0].id); // Set the first plan's ID as default
  //       }
  //            })


  //     .catch((error) => {
  //       console.error("Error fetching the package details:", error);
  //     });



  // },[])

  const handleContinue = async () => {
    
    setIsSubmitting(true); // Set submitting state to true
    setErrorMessage1(''); // Clear previous error messages

    try {
      const apiUrl = 'https://goapis.gosmart.ae/start_chat/'; // Replace with your actual API URL

      // Make the POST request
      console.log("email", email);
      const response = await axios.post(apiUrl, `"${email.trim()}"`); // Email as raw string with quotes
      console.log("Response",response)
      // Handle the response as needed
      console.log('Response:', response.data.result[0].email);
      const setEmailss = response.data.result[0].email;
      setEmail(setEmailss);
      closeModal();
      Openchatbox();
    } catch (error) {
      console.error('Error occurred while making the API request:', error);
      setErrorMessage1('An error occurred. Please try again.'); // Set error message
      } finally {
      setIsSubmitting(false); // Reset submitting state
    }
  };



  const openModal = () => {
    setErrorMessage1(''); // Clear previous error messages
    if (email) {
      // If email is present, call openChatBox directly
      Openchatbox();
    } else {
      // If email is not present, open the modal
      setShowModal(true);
    }
  };


  const Openchatbox = () => {

    console.log("email", email)

    if (email.trim()) {
      SetFlagingChatbox(true);
      closeModal();
    } else {
      alert("Please enter a valid email");
    }
  };

  const handleMinimize = () => setMinimized(!minimized); // Toggle minimized state

  const closeModal = () => {
    setShowModal(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }


  const handleSendMessage = async () => {
    if (!newMessage.trim() || isSending) return; // Prevent empty message or multiple clicks
    setIsSending(true); // Disable button while sending

    try {
      const response = await axios.post("https://goapis.gosmart.ae/send_chat_message/", {
        email: email, // Replace with the recipient's email
        message: newMessage,
      });
      console.log(response,"send message")

      if (response.status === 200) {
        console.log(response,"send message")
        // alert("Message sent successfully!");
        setNewMessage(""); // Clear the input field
      } else {
        // alert("Failed to send message.");
        console.log(response,"send message")

      }
    } catch (error) {
      console.error("Error sending message:", error);
      // alert("An error occurred while sending the message.");
    }
    finally {
      setIsSending(false); // Re-enable button after response
    }
  };


  return (
    <>
      {!FlagingChatbox && (
        <div className='Chat-icon-alignment'>
          <button onClick={openModal}>
            Start Chat

            <img src={chaticon} />

          </button>

        </div>
      )}
      {FlagingChatbox && (
        <div className='chatbox-container'>

          <div className='blue-top-area' onClick={handleMinimize}>

            <div className='flexing-top-area'>
              <img src={chatbox} />
              <h2 className='Title-top-area'>Go Smart Chat</h2>
            </div>

          </div>
          {!minimized && (
            <div>
              <div className="chat-history" style={{ maxHeight: "400px", overflowY: "auto" }}>
                {messages.map((msg) => (
                  <div key={msg.id}>
                    <div
                      className={`message message-${msg.sender === 'Client' ? 'Client' : 'System'
                        }`}
                    >              <p>{msg.message}</p>
                    </div>
                    <small
                      className={`timestamp ${msg.sender === 'Client' ? 'client-timestamp' : 'system-timestamp'
                        }`}
                    >


                      {new Date(msg.received_at).toLocaleString()}
                    </small>
                  </div>
                ))}
              </div>

              <div className='flexing-message-input'>
                <form className='flexing-message-input' onSubmit={(e) => e.preventDefault()}>
                  <input
                    type="text"
                    placeholder="Enter Your Message"
                    className='form-control'
                    style={{ fontSize: "16px" }}
                    onChange={(e) => setNewMessage(e.target.value)}
                    value={newMessage}

                  />
                  <button onClick={handleSendMessage}>➤</button>
                </form>
              </div>
            </div>
          )}
        </div>


      )}


      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel="Login Successful"
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="success-modal-container">
          <img
            src={chaticon2}
            alt="Verified"
            className="verification-img"
          />
        </div>

        <h2 className="success-title mb-3">Please Subscibe for Chating with us</h2>

        <div className='single-input'>


          <input type="email" className='form-control' onChange={handleEmailChange}
            onKeyDown={(e) => e.key === 'Enter' && handleContinue()}
            placeholder='Email' required />

          {errorMessage1 && <p className="error-message-email">{errorMessage1}</p>}

          <button className='continue-btn'
            disabled={isSubmitting} // Disable button while submitting
            onClick={handleContinue}>
            {isSubmitting ? "Submitting..." : "Continue"}
          </button>

        </div>

      </Modal>
    </>
  )
}
