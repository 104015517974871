import React from 'react'
import partner1 from "/webapps/gosmart_landingpage/src/assets/Equina.png";
import partner2 from "/webapps/gosmart_landingpage/src/assets/Contaqa.png";
import partner3 from "/webapps/gosmart_landingpage/src/assets/RAKICT.png";
import partner4 from "/webapps/gosmart_landingpage/src/assets/Ordarawy.png";
import partner5 from "/webapps/gosmart_landingpage/src/assets/Logo@2x (1).png";
import partner6 from "/webapps/gosmart_landingpage/src/assets/Asset 1@4x.png";
import partner7 from "/webapps/gosmart_landingpage/src/assets/Aida It.png";
import partner8 from "/webapps/gosmart_landingpage/src/assets/KhanMarkets.png";
import partner9 from "/webapps/gosmart_landingpage/src/assets/omega logo-New.png";
import partner10 from '/webapps/gosmart_landingpage/src/assets/Group 2049.png'
import partner11 from '/webapps/gosmart_landingpage/src/assets/diettodoor.png'
export default function Partner() {
    return (
        <div style={{ backgroundColor: "#F8FDFF" }}>
            <div className=" Partner pt-3 pb-5" >

                <h3>Partners</h3>
                <p>Whether you need custom applications, integrated management systems, or software solutions that facilitate your company's workflow, we are here to meet all your requirements effectively and efficiently. </p>
                <div className="flexing-partners-list">

                    <div className="flexing-partners-list-element">

                        <img src={partner1} />
                    </div>

                    <div className="flexing-partners-list-element">

                        <img src={partner2} />
                    </div>
                    <div className="flexing-partners-list-element">

                        <img src={partner3} />
                    </div>
                    <div className="flexing-partners-list-element">

                        <img src={partner4} />
                    </div>
                    <div className="flexing-partners-list-element">

                        <img src={partner5} />
                    </div>
                    <div className="flexing-partners-list-element">

                        <img src={partner6} className='classi-img' />
                    </div>

                    <div className="flexing-partners-list-element">

                        <img src={partner7} />


                    </div>

                    <div className="flexing-partners-list-element">

                        <img src={partner8} />


                    </div>


                    <div className="flexing-partners-list-element">

                        <img src={partner9} className='omega-img' />


                    </div>

                    <div className="flexing-partners-list-element">

                        <img src={partner10} className='banna2' />


                    </div>

                    <div className="flexing-partners-list-element">

                        <img src={partner11} className='diettodoor' />


                    </div>

                </div>
            </div>
        </div>
    )
}
